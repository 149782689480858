import "./AboutPage.css";
import React, { useEffect} from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default function AboutPage(){

    

    useEffect(()=> {
        gsap.registerPlugin(ScrollTrigger);
        gsap.to(".about_text",{
            scrollTrigger: {
                trigger:".about_main",
                start: "top center",
                scrub: true,
                end: "bottom center"
            },
            duration: 1,
            opacity: 2,
            y: "300px"
        })
    }, []);



    return(
        <div className="about_container" id="about">
            <div className="about_main">
                <div className="about_text">
                    <h2>Hi. I'm Nikhil.</h2>
                    <h4>I'm a Developer passionate about making the web a better place. I love solving design challenges, crafting user-friendly interfaces, and bringing creative ideas to life with HTML, CSS, Tailwind CSS, and ReactJS. With expertise in both front-end technologies and backend technologies such as C# and .NET, I'm dedicated to creating innovative solutions and continuously learning and growing in my field. </h4>
                </div>
            </div>
        </div>
    );
}