import "./Footer.css";
import linkedInImg from "../assets/linkedin.svg";
import githubImg from "../assets/github.svg";

export default function Footer(){
    return(
        <div className="footer_container">
            <div className="footer_icons">
                <a href="https://www.linkedin.com/in/nikhilbokade/" target="_blank" rel="noopener noreferrer">
                    <img src={linkedInImg} alt="linkedIn" />
                </a>

                <a href="https://github.com/nikhilbokade25" target="_blank" rel="noopener noreferrer">
                    <img src={githubImg} alt="github profile" />
                </a>
            </div>
        </div>
    );
}