import "./Projects.css";
import React,{useEffect} from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import rentyourkicksImg from "../assets/sneakerRental.jpg";
import pokepediaImg from "../assets/pokepedia.jpg";

export default function Projects(){

    useEffect(()=>{
        gsap.registerPlugin(ScrollTrigger);

        gsap.to(".project_title",{
            scrollTrigger: {
                trigger:".project_main",
                start: "top center",
                end: "bottom center",
                scrub: true,
            },
            opacity:3,
            duration: 1.2,
            y: "130px",
            fontSize: "50px"
        })   

        gsap.to(".project_1_container", {
            scrollTrigger: {
              trigger: ".project_main",
              start: "5% top ",
              end: "bottom center",
              scrub: true,
            },
            opacity: 5, 
            duration: 1,
          });

          gsap.to(".project_2_container", {
            scrollTrigger: {
              trigger: ".project_main",
              start: "7% top ",
              end: "bottom center",
              scrub: true,
            },
            opacity: 5, 
            duration: 1,
          });



        
    })
    return(
        <div className="project_main" >
            <h1 className="project_title">Latest Works</h1>
            <div className="project_container" id="projects">
                
                    <div className="project_1_container">
                        <div className="project_1_image">
                            <img src={rentyourkicksImg} alt="RentYourKicks"/>
                        </div>

                        <div className="project_1_description">
                            <h3>The website, developed using <span className="highlight">ReactJS</span>, offers a user-friendly interface for renting sneakers. It utilizes <span className="highlight">GSAP</span> for scroll animations, enhancing the visual appeal and interactivity of the site. Leveraging <span className="highlight">React Hooks</span> for state management ensures efficient data handling, while the responsive design guarantees a seamless user experience across various devices. </h3>
                            <button><a href="https://rentyourkicks.vercel.app/" target="_blank" rel="noopener noreferrer">Live Site</a></button>
                        </div>
                    </div>
            
                


                    <div className="project_2_container">
                        <div className="project_2_image">
                            <img src={pokepediaImg} alt="pokepedia"/>
                        </div>

                        <div className="project_2_description">
                            <h3>I developed a <span className="highlight">ReactJS</span> website using the <span className="highlight">PokeAPI</span> to provide users with Pokémon stats. It features a dialog box for easy exploration of key information like power and HP. The interface is user-friendly, allowing effortless navigation, and the site is responsive for use on various devices.</h3>
                            <button><a href="https://nikhilbokade25.github.io/pokepedia/" target="_blank" rel="noopener noreferrer">Live Site</a></button>
                        </div>
                    </div>
                
            </div>
        </div>
    );
}