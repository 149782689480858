import {FaBars, FaTimes} from "react-icons/fa";
import { useRef } from "react";
import "./HomePage.css";
import profileImg from "../assets/IMG_2703.jpg";
import linkedinSVG from "../assets/linkedin.svg";
import githubSVG from "../assets/github.svg";
import { Link } from "react-scroll";


export default function HomePage(){

    const navRef = useRef(null);
    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
        navRef.current.classList.toggle("nav_closed");
    }

    const hideNavbar = () =>{
        navRef.current.classList.remove("responsive_nav");
    }

    return(
        <div className="animation-sequence">
            <div className="background_block"/>
            <div className="slide_block"/>
            <div className="main_display">
                <div className="header">
                    <h3>Nikhil Bokade</h3>
                    <nav ref={navRef} className="nav">
                        <a href="#home" ><Link onClick={hideNavbar} to="home" smooth={true} duration={700} >Home</Link></a>
                        <a href="#about" ><Link onClick={hideNavbar} to="about" smooth={true} duration={1000}>About</Link></a>
                        <a href="#projects" ><Link onClick={hideNavbar} to="projects" smooth={true} duration={1500}> Projects</Link></a>
                        <a href="#contact" ><Link onClick={hideNavbar} to="contact" smooth={true} duration={1700}>Contact</Link></a>
                        <button className="nav_btn nav_btn_close" onClick={showNavbar}>
                            <FaTimes /><span>CLOSE</span>
                        </button>
                    </nav>
                    <button className="nav_btn nav_btn_menu" onClick={showNavbar}>
                        <FaBars/><span>MENU</span>
                    </button>
                </div>

                <div className="main_container" id="home">
                    <div className="main_text">
                        <div className="text_container">
                            <h2>Nikhil Bokade</h2>
                            <div className="description_text">
                                <p>An aspiring Entry-Level Software Developer eager to learn and grow in the world of software development. </p>
                            </div>
                            
                            <div className="socials">
                                <a href="https://www.linkedin.com/in/nikhilbokade/" target="_blank" rel="noopener noreferrer">
                                    <img src={linkedinSVG} alt="linkedin"/>
                                </a>

                                <a href="https://github.com/nikhilbokade25" target="_blank" rel="noopener noreferrer">
                                    <img src={githubSVG} alt="github" />
                                </a>
                            </div>
                        </div> 
                    </div>

                    <div className="main_image">
                        <div className="image_container">
                            <img className="image" src={profileImg} alt="profile"/>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}