
import './App.css';
import AboutPage from './Components/AboutPage';
import Contact from './Components/Contact';
import HomePage from './Components/HomePage';
import Projects from './Components/Projects';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <HomePage />
      <AboutPage />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
