import React from "react";
import "./Contact.css";
import mailIcon from "../assets/mail_icon.png";

export default function Contact(){
    return(
        <div className="contact_container" id="contact">
            <div className="contact_main">
                <h2>Let's talk ?</h2>
                <div className="contact_mail">
                    <img src={mailIcon} alt="mail me" />
                    <a href="mailto:nikhilbokade25@gmail.com">
                        <h3>nikhilbokade25@gmail.com</h3>
                    </a>
                </div>
            </div>
        </div>
    );
}